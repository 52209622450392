.pick-up-location-label {
  color: #ffffff;
  background-color: #565656;
  border-radius: 2px;
  border-right: 6px solid #565656;
  border-left: 6px solid #565656;
  border-top: 5px solid #565656;
  border-bottom: 5px solid #565656;
  max-width: 200px;
  overflow: hidden;
  font-weight: 400;
}
